import { Socket, Channel } from "phoenix";
import { batch, createSignal } from "solid-js";

import { config, isProduction } from "#root/config";

type CreateDomainSocketArgs = {
  /**
   * Userful for logs
   */
  name: string;
  /**
   * Path of the socket endpoint
   */
  path: `/${string}`;
  /**
   * Parameters passed to the socket connection
   * (optional)
   */
  getSocketParams?: () => {
    [key: string]: string | undefined;
  };
  /**
   * Which channel is the socket should attach to
   */
  getChannelName: () => string;
};

/**
 * Socket abstraction layer, the minimal set to connect to a PHX channel
 */
export function createDomainSocket<
  InEvents extends Record<string, unknown>,
  OutEvents extends Record<string, { request: Object; response: unknown }>,
>({ name, path, getSocketParams, getChannelName }: CreateDomainSocketArgs) {
  const [getSocket, setSocket] = createSignal<Socket | null>(null);
  const [getChannel, setChannel] = createSignal<Channel | null>(null);
  /**
   * Keep an array of tuple of events registered
   * [].at(0) is the event name
   * [].at(1) is the event listener number
   */
  let eventListeners: Array<[string, number]> = [];

  function initializeSocket() {
    const newSocket = new Socket(`${config.WS_URL}${path}`, {
      params: getSocketParams?.(),
      logger(kind, message, data) {
        if (isProduction) return;
        console.debug("[Socket]", name, kind, message, data);
      },
    });
    newSocket.connect();

    const newChannel = newSocket.channel(getChannelName());
    newChannel.join().receive("ok", () => {
      console.debug("[Socket]", name, "Connected to channel");
    });
    newChannel.onClose(() => {
      console.debug("[Socket]", name, "Leaving channel");
    });

    batch(() => {
      setSocket(newSocket);
      setChannel(newChannel);
    });
  }

  function disconnectSocket() {
    const channel = getChannel();
    const socket = getSocket();

    eventListeners.forEach(([eventName, listenerId]) => {
      channel?.off(eventName, listenerId);
    });
    eventListeners = [];

    channel?.leave();
    socket?.disconnect();
  }

  function addEventListener<T extends Exclude<keyof InEvents, symbol | number>>(
    eventName: T,
    callback: (arg: InEvents[T]) => void,
  ) {
    const channel = getChannel();
    if (!channel) return;

    const listenerId = channel.on(eventName, (e: InEvents[T]) => {
      callback(e);
    });
    eventListeners.push([eventName, listenerId]);
  }

  function removeEventListener<
    T extends Exclude<keyof InEvents, symbol | number>,
  >(eventName: T, callbackId: number) {
    const channel = getChannel();
    if (!channel) return;

    channel.off(eventName, callbackId);
    eventListeners = eventListeners.filter(
      ([evtName, id]) => eventName !== evtName && callbackId !== id,
    );
  }

  function pushEvent<T extends Exclude<keyof OutEvents, symbol | number>>(
    event: T,
    data: OutEvents[T]["request"],
    onResponse: (response: OutEvents[T]["response"]) => void,
  ) {
    const channel = getChannel();
    if (!channel) return;

    return channel.push(event, data).receive("ok", onResponse);
  }

  function isConnected() {
    return getSocket() !== null && getChannel() !== null;
  }

  return {
    isConnected,
    initializeSocket,
    disconnectSocket,
    addEventListener,
    removeEventListener,
    pushEvent,
  };
}
