import {
  createMutation,
  createQuery,
  useQueryClient,
} from "@tanstack/solid-query";
import { HTTPError } from "ky";
import { createEffect, createSignal, onCleanup, onMount } from "solid-js";

import {
  Session,
  Question,
  fetchSessions,
  fetchSession,
  fetchSessionQuestions,
  postSessionNextQuestion,
  postSessionSkipQuestion,
  createNewSession,
  deleteSessionTerminate,
  SessionResponse,
  CreateSessionData,
  APIError,
  APIFormError,
  postSessionClearQuestion,
  postSessionRate,
  RateSessionData,
  postSessionFocusQuetion,
} from "#root/module/api";
import { useHttp } from "#root/domain/http";
import { useUserContext } from "#root/domain/user";
import { createDomainSocket } from "#root/module/socket";

import { destructureQuestionArray } from "./sessionHelpers";
import {
  USER_REQUEST_KEY,
  SESSIONS_KEY,
  SESSION_KEY,
  SESSION_QUESTIONS_KEY,
} from "./cacheKeys";

export const useSessions = () => {
  const { getClient } = useHttp();

  return createQuery<Session[], HTTPError>(() => {
    return {
      queryKey: [SESSIONS_KEY],
      queryFn: () => fetchSessions(getClient()).then(({ data }) => data),
    };
  });
};

export const useCreateSession = () => {
  const { getClient } = useHttp();
  const queryClient = useQueryClient();
  const [errorResponse, setErrorResponse] = createSignal();

  const createSession = createMutation<Session, HTTPError, CreateSessionData>(
    () => {
      return {
        mutationFn: (data: Parameters<typeof createNewSession>[1]) =>
          createNewSession(getClient(), data).then(({ data }) => data),
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [USER_REQUEST_KEY],
          });
        },
        onError: async (error) => {
          const errorJson = await error.response.json();
          setErrorResponse(errorJson);
        },
      };
    },
  );

  const errors = () => {
    if (!createSession.isError || errorResponse() == null) return undefined;

    switch (createSession.error.response.status) {
      case 422:
        return (errorResponse() as APIFormError)?.errors?.form;
      default:
        return { global: (errorResponse() as APIError)?.errors?.details };
    }
  };

  return {
    createSession,
    errors,
  };
};

export const useSession = (sessionId: () => string) => {
  const { getClient } = useHttp();
  const queryClient = useQueryClient();

  const session = createQuery<Session, HTTPError>(() => {
    return {
      queryKey: [SESSION_KEY, sessionId()],
      queryFn: () => {
        const client = getClient();
        return fetchSession(client, sessionId()).then(({ data }) => data);
      },
    };
  });

  const nextQuestion = createMutation(() => {
    return {
      mutationFn: () => {
        return postSessionNextQuestion(getClient(), sessionId());
      },
      onSettled: () =>
        queryClient.invalidateQueries({
          queryKey: [SESSION_QUESTIONS_KEY, sessionId()],
          exact: true,
        }),
    };
  });

  const clearQuestion = createMutation(() => {
    return {
      mutationFn: () => {
        return postSessionClearQuestion(getClient(), sessionId());
      },
      onSettled: () =>
        queryClient.invalidateQueries({
          queryKey: [SESSION_QUESTIONS_KEY, sessionId()],
          exact: true,
        }),
    };
  });

  const endSession = createMutation<SessionResponse, HTTPError>(() => {
    return {
      mutationFn: () => deleteSessionTerminate(getClient(), sessionId()),
      onSuccess: ({ data }) => {
        queryClient.setQueryData([SESSION_KEY, sessionId()], data);
        queryClient.invalidateQueries({
          exact: true,
          queryKey: [SESSION_QUESTIONS_KEY, sessionId()],
        });
      },
    };
  });

  const rateSession = createMutation<
    SessionResponse,
    HTTPError,
    RateSessionData
  >(() => {
    return {
      mutationFn: (data) => postSessionRate(getClient(), sessionId(), data),
      onSuccess: ({ data }) => {
        queryClient.setQueryData([SESSION_KEY, sessionId()], data);
      },
    };
  });

  return {
    session,
    rateSession,
    nextQuestion,
    clearQuestion,
    endSession,
  };
};

export const useSessionQuestions = (sessionId: () => string) => {
  const { getClient } = useHttp();

  const questions = createQuery<
    Question[],
    HTTPError,
    ReturnType<typeof destructureQuestionArray>
  >(() => {
    return {
      initialData: [],
      queryKey: [SESSION_QUESTIONS_KEY, sessionId()],
      queryFn: () => {
        const client = getClient();
        return fetchSessionQuestions(client, sessionId()).then(
          ({ data }) => data,
        );
      },
      select: (questions) => destructureQuestionArray(questions),
    };
  });

  return questions;
};

export const useQuestionActions = (
  sessionId: () => string,
  questionId: () => string,
) => {
  const { getClient } = useHttp();
  const queryClient = useQueryClient();

  const skip = createMutation(() => {
    return {
      mutationFn: () =>
        postSessionSkipQuestion(getClient(), sessionId(), questionId()),
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: [SESSION_QUESTIONS_KEY, sessionId()],
          exact: true,
        }),
    };
  });

  const focus = createMutation(() => {
    return {
      mutationFn: () =>
        postSessionFocusQuetion(getClient(), sessionId(), questionId()),
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: [SESSION_QUESTIONS_KEY, sessionId()],
          exact: true,
        }),
    };
  });

  return {
    skip,
    focus,
  };
};

export type SocketStatus = {
  twitch: boolean;
};

export type SessionUpdateEvents = {
  ["question:new"]: { data: Question };
  ["question:focused"]:
    | { data: Question; status: "success" }
    | { status: "empty" };
  ["status:twitch:update"]: { data: { is_connected: boolean } };
  ["session:terminated"]: { data: Session; status: "success" };
};

export type SessionPushEvents = {
  "get:status": {
    request: {};
    response: { data: { twitch: boolean } };
  };

  "get:question:focused": {
    request: {};
    response: { data: Question; status: "success" } | { status: "empty" };
  };
};

type UserSessionUpdatesOptions = {
  needAuth: boolean;
};
export const useSessionUpdates = (
  getSessionId: () => string | undefined,
  { needAuth }: UserSessionUpdatesOptions,
) => {
  const userContext = needAuth ? useUserContext() : null;
  const {
    isConnected,
    initializeSocket,
    disconnectSocket,
    addEventListener,
    removeEventListener,
    pushEvent,
  } = createDomainSocket<SessionUpdateEvents, SessionPushEvents>({
    name: "Session",
    path: "/socket/session",
    getSocketParams: () => {
      const sessionId = getSessionId();
      const token = userContext?.socketToken.data;

      if (needAuth && token) {
        return { token, sessionId };
      }

      return { sessionId };
    },
    getChannelName: () => {
      const sessionId = getSessionId();
      return `session:${sessionId}`;
    },
  });

  createEffect<boolean>((hasBeenInitialized = false) => {
    const sessionId = getSessionId();

    if (hasBeenInitialized) {
      disconnectSocket();
    }

    const socketToken = userContext?.socketToken.data;
    const canBeInitialized =
      sessionId && ((needAuth && socketToken) || !needAuth);

    if (canBeInitialized) {
      initializeSocket();
      return true;
    }

    return false;
  });

  onCleanup(disconnectSocket);

  return {
    isConnected,
    pushEvent,
    addEventListener,
    removeEventListener,
  };
};

type SessionsUpdateEvents = {
  ["session:created"]: { data: Session; status: "success" };
  ["session:terminated"]: { status: "empty" };
};

type SessionsPushEvents = {
  ["get:session:active"]: {
    request: {};
    response: { data: Session; status: "success" } | { status: "empty" };
  };
};

export function useAccountWideSessionsUpdates(userId: string) {
  const {
    initializeSocket,
    disconnectSocket,
    addEventListener,
    removeEventListener,
    pushEvent,
    isConnected,
  } = createDomainSocket<SessionsUpdateEvents, SessionsPushEvents>({
    name: "Sessions",
    path: "/socket/sessions",
    getChannelName: () => `sessions:${userId}`,
    getSocketParams: () => ({
      userId,
    }),
  });

  onMount(() => {
    initializeSocket();
  });

  onCleanup(() => {
    disconnectSocket();
  });

  return {
    isConnected,
    pushEvent,
    addEventListener,
    removeEventListener,
  };
}
