import { type Question } from "#root/module/api";

export function destructureQuestionArray(questions: Question[]): {
  focused?: Question;
  next?: Question;
  list: Question[];
} {
  const { focused, list } = questions.reduce<{
    focused: undefined | Question;
    list: Question[];
  }>(
    (acc, item) => {
      if (item.isFocused) {
        return {
          ...acc,
          focused: item,
        };
      } else if (acc.list == undefined) {
        return {
          ...acc,
          baseList: [item],
        };
      } else {
        acc.list?.push(item);
        return acc;
      }
    },
    {
      focused: undefined,
      list: [],
    }
  );

  return {
    focused,
    list,
  };
}
